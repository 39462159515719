<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center">
          <h5 class="d-inline mr-2 font-weight-bold">Списания продукция</h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              placeholder="Найти"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.start_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              placeholder="от дата"
            ></el-date-picker>
            <el-date-picker
              class="ml-3"
              size="mini"
              v-model="filterForm.end_date"
              type="date"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              placeholder="до дата"
            ></el-date-picker>
          </div>
        </div>
        <div
          class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end"
        >
          <el-button
            class="mr-2"
            size="mini"
            @click="tableID = true"
            icon="el-icon-circle-plus-outline"
          >Создать</el-button>
          <el-dropdown class="mr-2">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(column,index) in columns" :key="index">
                <el-checkbox
                  :checked="column.show"
                  @change="column.show = !column.show"
                >{{ column.title }}</el-checkbox>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <thead>
          <tr>
            <th v-if="columns.id.show">{{ columns.id.title }}</th>
            <th v-if="columns.dataNomer.show">{{ columns.dataNomer.title }}</th>
            <th v-if="columns.provider.show">{{ columns.provider.title }}</th>
            <th v-if="columns.products.show">{{ columns.products.title }}</th>
            <th v-if="columns.summa.show">{{ columns.summa.title }}</th>
            <th v-if="columns.status.show">{{ columns.status.title }}</th>
            <th v-if="columns.settings.show">{{ columns.settings.title }}</th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.dataNomer.show">
              <el-date-picker
                clearable
                size="mini"
                type="date"
                v-model="filterForm.dataNomer"
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
                :placeholder="columns.dataNomer.title"
              ></el-date-picker>
            </th>
            <th v-if="columns.provider.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.provider"
                :placeholder="columns.provider.title"
              ></el-input>
            </th>
            <th v-if="columns.products.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.products"
                :placeholder="columns.products.title"
              ></el-input>
            </th>
            <th v-if="columns.summa.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.summa"
                :placeholder="columns.summa.title"
              ></el-input>
            </th>
            <th v-if="columns.status.show">
              <el-select
                v-model="filterForm.status"
                filterable
                clearable
                :placeholder="columns.status.title"
                size="mini"
              >
                <el-option label="Zone one" value="shanghai"></el-option>
                <el-option label="Zone two" value="beijing"></el-option>
              </el-select>
            </th>

            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr v-for="user in list" :key="user.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ user.id }}</td>
            <td v-if="columns.dataNomer.show">{{ user.dataNomer }}</td>
            <td v-if="columns.provider.show">{{ user.provider }}</td>
            <td v-if="columns.products.show">
              <a href="#">Просмотр</a>
            </td>
            <td v-if="columns.summa.show">{{ user.summa }}</td>
            <td v-if="columns.status.show">{{ user.status }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <el-dropdown szie="mini">
                <el-button size="mini" icon="el-icon-setting" round>
                  <i class="el-icon-arrow-down"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" size="mini">
                  <el-dropdown-item command="edit" icon="el-icon-edit el-icon--left">Изменить</el-dropdown-item>
                  <el-dropdown-item command="print" icon="el-icon-printer el-icon--left">Печать</el-dropdown-item>
                  <!-- <el-dropdown-item v-if="permissions.includes(name+'.'+'show')" command="show"  icon="el-icon-view el-icon--left"> Показать</el-dropdown-item> -->
                  <el-dropdown-item
                    command="back_material"
                    icon="el-icon-refresh-left el-icon--left"
                  >Возврат сырья</el-dropdown-item>
                  <el-dropdown-item
                    command="comments"
                    icon="el-icon-chat-line-square el-icon--left"
                  >Комментарий</el-dropdown-item>
                  <el-dropdown-item
                    command="delete"
                    icon="el-icon-delete el-icon--left"
                    @click="open"
                  >
                    <el-button class="uadlit" @click="open">Удалить</el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer size="85%" :visible.sync="tableID" :with-header="false">
      <CrmCreate />
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";

export default {
  name: "shipments",
  data() {
    return {
      data_s: "",
      loadingData: false,
      drawer: false,
      filterForm: {
        search: "",
        id: "",
        dataNomer: "",
        provider: "",
        summa: "",
        status: ""
      },
      list: [
        {
          id: "1",
          dataNomer: "2020-05-12 09:26",
          provider: "	Azizbek Eshonaliyev",
          data: "",
          summa: "Заказы готовой продукции	",
          status: "22.05.2020 13:04"
        }
      ],
      columns: {
        id: {
          show: true,
          title: "№",
          sortable: true,
          column: "id"
        },
        dataNomer: {
          show: true,
          title: "Время",
          sortable: true,
          column: "dataNomer"
        },
        provider: {
          show: true,
          title: "Кому",
          sortable: true,
          column: "provider"
        },

        products: {
          show: true,
          title: "Продукты",
          sortable: true,
          column: "products"
        },
        summa: {
          show: true,
          title: "Тип документа",
          sortable: true,
          column: "summa"
        },

        status: {
          show: true,
          title: "Создан в",
          sortable: true,
          column: "status"
        },

        settings: {
          show: true,
          title: "Настройки",
          sortable: false,
          column: "settings"
        }
      },
      tableID: false,
      tableUser: false
    };
  },
  components: {
    CrmCreate
  },
  methods: {
    open() {
      this.$confirm("Это навсегда удалит файл. Продолжить?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "Удалить завершено"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Удалить отменено"
          });
        });
    }
  }
};
</script>
